import './node_modules/bootstrap/dist/js/bootstrap.min.js';

import './node_modules/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';

import Sortable from 'sortablejs';

import Vue from 'vue';

var moment = require('moment');

var jQuery = require("jQuery");
window.$ = window.jQuery = jQuery;

$('.datepicker').datepicker({
    format: "yyyy-mm-dd"
});

$('.datetime').each(function() {
	var td_date = $(this).text();
	var stillUtc = moment.utc(td_date).toDate();
	var local = moment(stillUtc).local().format('M/D h:mm a');
	if (local == 'Invalid date') {
		$(this).text(td_date);
	} else {
		$(this).text(local);
	}
});

// CHECK SESSION 
  setInterval(function() {
    var d = new Date();
    var min = d.getMinutes();
    var sec = d.getSeconds();

    if ((min % 10 == 0) && (sec == 0)) { // every 10 mins
       $.ajax({
        method: "POST",
        url: "./ajax/check_session",
        data: { red_id:  true }
      }).done( function(data) {
          console.log('check_session');
        }
      );
    }
   }, 1 * 1000); // 60 * 1000 milsec


// USED FOR LANDING PAGE BUILDER
if ($("#sortable_blocks").length != 0) {
   Sortable.create(sortable_blocks, {
    animation: 100,
    group: 'list-1',
    draggable: '.list-group-item',
    handle: '.list-group-item',
    sort: true,
    filter: '.sortable-disabled',
    chosenClass: 'active'
  });

  if ($("#block_inventory").length != 0) {
    Sortable.create(block_inventory, {
      group: 'list-1',
      handle: '.list-group-item'
    });
  }
}




  //import pin from './Templates/components/PinComponent.vue';
  //import wantHaveAdd from './Templates/components/WantHaveAddComponent.vue';
  // import BrandLogoComp from './Templates/components/BrandLogoComponent.vue';
  // import DashWidgetsComp from './Templates/components/DashWidgetsComponent.vue';
  // import LoginComp from './Templates/components/LoginComponent.vue';

/// VUE COMPONENTS
// if ($("#loginComp").length != 0) {
// 	new Vue({  render: createElement => createElement(LoginComp, {
// 		props: {
// 			title: document.getElementById("loginComp").getAttribute("title"),
// 			url: document.getElementById("loginComp").getAttribute("url"),
// 			email:document.getElementById("loginComp").getAttribute("email"),
// 			action:document.getElementById("loginComp").getAttribute("action"),
// 		}
// 	}) }).$mount('#loginComp');
// }

// if ($("#dashWidgetsComp").length != 0) {
//   new Vue({  render: createElement => createElement(DashWidgetsComp, {
//     props: {
//       title: document.getElementById("dashWidgetsComp").getAttribute("title"),
//       url: document.getElementById("dashWidgetsComp").getAttribute("url"),
//       sales: $.parseJSON(document.getElementById("dashWidgetsComp").getAttribute("sales")),
//       orders: $.parseJSON(document.getElementById("dashWidgetsComp").getAttribute("orders")),
//     }
//   }) }).$mount('#dashWidgetsComp');
// }

// if ($("#brandLogoComp").length != 0) {
//   new Vue({  render: createElement => createElement(BrandLogoComp, {
//     props: {
//       items: $.parseJSON(document.getElementById("brandLogoComp").getAttribute("items")),
//       img_path: document.getElementById("brandLogoComp").getAttribute("img_path"),
//     }
//   }) }).$mount('#brandLogoComp');
// }


/// THIS VUE SOLUTION https://forum.vuejs.org/t/passing-props-to-root-instances-in-2-0/244/23


//// PARCEL DOC
//// https://scotch.io/tutorials/using-parcel-in-a-vuejs-app


 